import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDj6pUH3F9Pm687tflt1osfThopgMu5wNM",
    authDomain: "clone-31550.firebaseapp.com",
    databaseURL: "https://clone-31550.firebaseio.com",
    projectId: "clone-31550",
    storageBucket: "clone-31550.appspot.com",
    messagingSenderId: "83183116325",
    appId: "1:83183116325:web:54eef6c67a1294191b5fcc",

};

// Iinitialize the app. by passing in the firebaseConfig above.
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize database. firestore is the realtime database in firebase.
const db = firebaseApp.firestore();

//
const auth = firebase.auth();

export { db, auth };
