export const initialState = {
    basket: [],
    user: null

};

// Selector: highly used on production environments.
// get the basket, with optional chaining to allow for elegent breaking, 
// reduce is an extremely powerful function used to map through the basket
// and tally up the total.
export const getBasketTotal = (basket) =>
    basket?.reduce((amount, item) => item.price + amount, 0);


//
const reducer = (state, action) => {
    // Test
    console.log("IN REDUCER, ACTION DISPATCHED: ", action);

    // Switch
    switch (action.type) {
        case 'ADD_TO_BASKET':
            return {
                ...state,
                basket: [...state.basket, action.item],
            };

        case 'EMPTY_BASKET':
            return {
                ...state,
                basket: [] //return basket to its original empty array.
            }

        case "REMOVE_FROM_BASKET":
            const index = state.basket.findIndex(
                (basketItem) => basketItem.id === action.id
            );

            let newBasket = [...state.basket];

            if (index >= 0) {
                newBasket.splice(index, 1);
            } else {
                console.warn(
                    `Cannot remove product (id: ${action.id}) as its not in the basket!`
                )
            }

            return {
                ...state,
                basket: newBasket,
            };

        case "SET_USER":
            return {
                ...state,
                user: action.user
            }

        //    
        default:
            return state;
        ////
    }
};

export default reducer;